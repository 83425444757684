<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :afterIcon="
              [
                {
                  name: 'search',
                  click: true,
                  callbackName: 'searchWorkPermit',
                },
                {
                  name: 'close',
                  click: true,
                  callbackName: 'removeWorkPermit',
                },
              ]
            "
            :editable="editable"
            :readonly="true"
            label="작업허가번호"
            name="permitNo"
            v-model="searchParam.permitNo"
            @searchWorkPermit="searchWorkPermit"
            @removeWorkPermit="removeWorkPermit"
          >
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="작업기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-process
            label="공정"
            name="processCd"
            v-model="searchParam.processCd"
          >
          </c-process>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :disabled="true"
            label="업체"
            name="vendorName"
            v-model="searchParam.vendorName"
          />
        </div>
      </template>
    </c-search-box>
      <c-table
        ref="table"
        title="TBM 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="grid.data"
        :columnSetting="false"
        :isFullScreen="false"
        :usePaging="false"
        :expandAll="true"
        @linkClick="linkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="등록" icon="add" @btnClicked="linkClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
      </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'tbm',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:110px',
            fix: true,
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmNo',
            field: 'tbmNo',
            label: 'TBM번호',
            style: 'width:150px',
            fix: true,
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            fix: true,
            type: 'link',
            sortable: true,
          },
          {
            name: 'permitNo',
            field: 'permitNo',
            label: '작업허가번호',
            style: 'width:150px',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          {
            name: 'tbmWorkDate',
            field: 'tbmWorkDate',
            label: '작업일',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            style: 'width:150px',
            label: '공정',
            align: 'left',
            sortable: true,
          },
          // {
          //   name: 'vendorName',
          //   field: 'vendorName',
          //   style: 'width:120px',
          //   label: '업체',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'sopName',
            field: 'sopName',
            style: 'width:150px',
            // 작업
            label: '작업',
            align: 'left',
            sortable: true,
          },
          {
            name: 'tbmWorkResponsibleName',
            field: 'tbmWorkResponsibleName',
            label: '작업책임자',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTime',
            field: 'workTime',
            label: '작업시간',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            style: 'width:250px',
            label: '보충작업',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      period: ['', ''],
      searchParam: {
        startYmd: '',
        endYmd: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        mdmSopId: '',
        sopName: '',
        permitNo: '',
        sopWorkPermitId: '',
        sopMapId: '',
        mapName: '',
        tbmCompleteFlag: null,

      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      statusItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '작성완료' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.searchParam.vendorCd = this.$store.getters.user.userId
      this.searchParam.vendorName = this.$store.getters.user.userName

      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.tbm.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      this.detailPopup(row, col);
    },
    detailPopup(result, col) {
      if (col && col.name === 'permitNo') {
        this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitDetail.vue"}`);
        this.popupOptions.title = '작업허가서 상세';
        this.popupOptions.param = {
          sopWorkPermitId: result ? result.sopWorkPermitId : '',
          permitTypeCd: result ? result.permitTypeCd : '',
        };
      } else {
        this.popupOptions.target = () =>
        import(`${"./tbmDetail.vue"}`);
        this.popupOptions.title = 'TBM 상세';
        this.popupOptions.param = {
          tbmId: result ? result.tbmId : '',
        };
      }
      this.popupOptions.isFull = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.sopWorkPermitId = data[0].sopWorkPermitId;
        this.searchParam.permitNo = data[0].permitNo;
      }
    },
    removeWorkPermit() {
      this.searchParam.sopWorkPermitId = '';
      this.searchParam.permitNo = '';
    },
  }
};
</script>
